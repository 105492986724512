.navbar {
    display: flex;
    justify-content: space-around;
    padding: 16px;
    box-shadow: 0px 1px -2px black;
    height: 15vh;
    margin: 0;

}

.nav-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.nav-logo p {
    font-size: 38px;
    color: #171717;
    font-weight: 600;
    font-family: 'poppins';
}

.nav-menu {
    display: flex;
    font-weight: 500;
    font-size: 20px;
    gap: 50px;
    color: #626262;
    list-style: none;
    align-items: center;
}

.nav-menu li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    cursor: pointer;
}

.nav-menu hr {
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background: #FF4141;
}

.nav-login-cart {
    display: flex;
    align-items: center;
    gap: 45px;
}

.nav-login-cart button {
    width: 100px;
    height: 40px;
    outline: none;
    border: 1px solid #7a7a7a;
    border-radius: 75px;
    color: #515151;
    font-size: 20px;
    font-weight: 500;
    background: white;
    cursor: pointer;
}

.nav-login-cart button:active {
    background: #f3f3f3;
}

.nav-cart-count {
    display: flex;
    width: 22px;
    height: 22px;
    align-items: center;
    justify-content: center;
    margin-top: -35px;
    margin-left: -55px;
    border-radius: 11px;
    font-size: 14px;
    background: red;
    color: white;
}

.navlink {
    text-decoration: none;
    color: #626262;
    font-family: 'poppins';
}