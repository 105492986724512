.item{
    width: 350px;
    border-radius: 5px;
    border: none;
}

.item img{
    border-radius: 5px;

}

.item p{
    margin: 6px 0px;
}

.item-prices{
    display: flex;
    gap: 20px;
}

.item-price-new{
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}

.item-price-old{
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}

.item:hover{
    scale: 1.03;
    transition: 0.6s;
}