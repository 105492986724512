.popular {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: 90vh;
}

.popular h1 {
    color: #171717;
    font-size: 50px;
    font-weight: 600;
}

.popular hr {
    width: 200px;
    height: 6px;
    border-radius: 10px;
    background: #252525;
}

.popular-item {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 30px;
}