.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    background: linear-gradient(180deg, #fde1ff, #e1ffea22 60%);
}

.footer-logo{
    display: flex;
    align-items: center;
    gap: 20px;
}

.footer-logo p{
    color: #383838;
    font-size: 46px;
    font-weight: 700;
}

.footer-links{
    display: flex;
    list-style: none;
    gap: 30px;
    color: #252525;
    font-size: 20px;
}
 

.footer-links li{
    cursor: pointer;
}

.footer-social-icon{
    display: flex;
    justify-content: center;
    gap: 20px;
}

.footer-icons-container{
    padding: 10px;
    padding-bottom: 6px;
    background: #fbfbfb;
    border: 1px solid #ebebeb;
}

.footer-copyright{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    margin-bottom: 30px;
    color: #1a1a1a;
    font-size: 20px;
}

.footer-copyright hr{
    width: 80%;
    border: none;
    border-radius: 10px;
    height: 3px;
    background: #c7c7c7;
}
